var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mr-3"},[_c('v-row',[_c('v-col',{},[_c('switchGroup',_vm._g({attrs:{"type":"switchGroup","fieldAttrInput":_vm.fieldAttrInput,"fieldAttributes":{
          name: 'Test Direction',
          fields: _vm.templateContent.fields.uplinkDownlinkSwitch.fields,
        },"value":_vm.valueLine[_vm.field],"field":"uplinkDownlinkSwitch","templateContent":_vm.templateContent,"valueLine":_vm.valueLine,"disabled":_vm.fieldAttrInput.disabled,"id":"test_direction_iperf"}},_vm.$listeners))],1),_c('v-col',{staticClass:"mt-6 mr-n3",attrs:{"cols":"1"}},[_c('associatedOption',{attrs:{"fieldAttributes":{ name: 'Test Type', associatedOption: _vm.tType },"type":"select","fieldAttrInput":{ class: 'required' },"templateContent":_vm.result,"isValid":_vm.isValid,"field":"timeVol"},model:{value:(_vm.timeVol),callback:function ($$v) {_vm.timeVol=$$v},expression:"timeVol"}})],1),_c('v-col',{staticClass:"mt-6 mr-n3",attrs:{"cols":"2"}},[_c('defaultFieldTypes',{attrs:{"templateContent":_vm.result,"field":"timeVolValue","fieldAttributes":{
          ..._vm.fieldAttributes,
          fieldAttrInput: _vm.fieldAttrInputSpecial,
          type: _vm.type,
          name: _vm.name,
        }},model:{value:(_vm.timeVolValue),callback:function ($$v) {_vm.timeVolValue=$$v},expression:"timeVolValue"}})],1),_c('v-col',{staticClass:"mt-6"},[_c('fieldsRows',_vm._g({attrs:{"templateContent":_vm.templateContentRow,"valueLine":_vm.valueLine,"isValid":_vm.isValid}},_vm.$listeners))],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }